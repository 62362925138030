<template>
    <b-card title="Customer Details">
        <b-form>
            <b-row>
                <b-col md="3">
                    <h3>{{ fullName }}</h3>
                    <p>{{ address }}</p>
                </b-col>
                <b-col md="3">
                    <h4>{{ mobileNo }}</h4>
                    <p>NA</p>
                </b-col>
                <b-col md="3">
                    <h4>{{ emailId }}</h4>
                    <p v-if="remark">{{ remark }}</p>
                    <b-button variant="success" class="mt-1 mr-1" @click="createNewOrder">Create Order</b-button>
                </b-col>
                <b-col md="3">
                    <b-col>
                        <b-row>
                            <h5 class="mr-1">Wallet Balance : </h5>
                            <h5>{{ walletBalance }}</h5>
                        </b-row>
                        <b-row>
                            <h5 class="mr-1">Topup / Add Money : </h5>
                            <b-button class="addTopUp" variant="primary" v-b-toggle.sidebar-createTransaction
                                @click="topUpAddMoney"><feather-icon icon="PlusIcon" /></b-button>
                        </b-row>
                        <b-row>
                            <h5>Last Visited : {{ lastVisit }}</h5>
                        </b-row>
                    </b-col>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                
                <b-col md="4" class="mt-1">

                    <b-dropdown block text="Last Payment Made" @input="getLastpaymentList" variant="primary">
                        <b-dropdown-item class="w-100" v-for="lastTransaction in customerLastTransactionOptions"
                            :key="lastTransaction">
                            <b-row style="padding: 0px;">
                                <b-col md="3" class="text-center">
                                    <h6 v-if="lastTransaction.orderNo != null">Order No</h6>
                                    <p class="lastPaymentFontSize" v-if="lastTransaction.orderNo != null">#{{
                                        lastTransaction.orderNo
                                    }}</p>
                                    <h6 v-if="lastTransaction.walletId > 0">Wallet Id</h6>
                                    <p class="lastPaymentFontSize" v-if="lastTransaction.walletId > 0">#{{
                                        lastTransaction.walletId
                                    }}</p>
                                </b-col>
                                <b-col md="3" class="text-center">
                                    <h6>Payment</h6>
                                    <p class="lastPaymentFontSize">{{ lastTransaction.paymentMode }}</p>
                                </b-col>
                                <b-col md="3" class="text-center">
                                    <h6>Date</h6>
                                    <p class="lastPaymentFontSize">{{
                                        convertTimeStampToDateWithoutTime(lastTransaction.createdDate)
                                    }}</p>
                                </b-col>
                                <b-col md="3" class="text-center">
                                    <h6>Amount</h6>
                                    <p v-if="lastTransaction.transactionType == 'CREDIT'"
                                        class="amountCredit lastPaymentFontSize">{{
                                            lastTransaction.amount
                                        }}</p>
                                    <p v-if="lastTransaction.transactionType == 'DEBIT'"
                                        class="amountDebit lastPaymentFontSize">{{
                                            lastTransaction.amount
                                        }}</p>
                                </b-col>
                            </b-row>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col md="4" class="mt-1">
                    <b-dropdown block text="Show Details" variant="primary">
                        <b-dropdown-item>
                            <b-row>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Preference :</span>
                                    <label class="mr-2">{{ customerPreference }}</label>
                                    <span class="mr-2">Discount :</span>
                                    <label class="mr-2">{{ discount }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Communication Preference :</span>
                                    <label class="mr-2">{{ communicationPreference }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Home Delivery :</span>
                                    <label class="mr-2">{{ homeDelivery }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Customer GST IN :</span>
                                    <label class="mr-2">{{ gstin }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Membership Id :</span>
                                    <label class="mr-2">{{ membershipId }}</label>
                                    <span class="mr-2">Barcode :</span>
                                    <label class="mr-2">{{ barcode }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Marketing Source :</span>
                                    <label class="mr-2">{{ marketingSource }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Alternate Phone :</span>
                                    <label class="mr-2">{{ alternatePhone }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Facebook Id :</span>
                                    <label class="mr-2">{{ facebookId }}</label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Message On Search Customer :</span>
                                    <label class="mr-2">{{ messageOnSearchCustomer }} </label>
                                </b-col>
                                <b-col md="12" class="border">
                                    <span class="mr-2">Message On Garment Delivery :</span>
                                    <label class="mr-2">{{ messageOnGarmentDelivery }}</label>
                                </b-col>
                            </b-row>
                        </b-dropdown-item>
                    </b-dropdown>
                </b-col>
                <b-col md="4" class="mt-1">
                    <b-button variant="primary" block
                        :to="{ name: 'editCustomer', params: { id: this.customerId } }">Edit</b-button>
                </b-col>
            </b-row>
            <hr>

            <b-col>
                <b-row>
                    <b-col md="3">
                        <b-row>
                            <p class="mr-1">Pending Order : </p>
                            <h3>{{ pendingOrder }}</h3>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <p class="mr-1">Amount Due : </p>
                            <h3>{{ amountDue }}</h3>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <p class="mr-1">Pcs Under Process : </p>
                            <h3>{{ pcsUnderProcess }}</h3>
                        </b-row>
                    </b-col>
                    <b-col md="3">
                        <b-row>
                            <p class="mr-1">Pcs Ready : </p>
                            <h3>{{ pcsReady }}</h3>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <hr>
            <b-row>
                <b-col md="4" v-if="roles[0].name == 'ADMIN'">
                    <b-form-group label="Store Name">
                        <v-select v-model="storeName" label="storeName" :options="storeNameOptions" />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Order Status">
                        <v-select v-model="orderStatus" placeholder="Select Order Status"
                            :options="orderStatusOptions" />
                    </b-form-group>
                </b-col>
                <b-col md="2">
                    <b-button block class="mt-2" variant="primary" @click="getOrderList(customerId)">Filter</b-button>
                </b-col>
            </b-row>
        </b-form>
        <div>
            <hr>
            <!-- search input -->
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <label class="mr-1">Search</label>
                        <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                    </div>
                </b-form-group>
            </div>

            <!-- table -->
            <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">

                    <!-- Column: Name -->
                    <span v-if="props.column.field === 'orderCreateDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDateLocal(props.row.orderCreateDate) }}</span>
                    </span>

                    <!-- Column: Name -->
                    <span v-else-if="props.column.field === 'dueDate'" class="text-nowrap">
                        <span class="text-nowrap">{{ convertTimeStampToDate(props.row.dueDate) }}</span>
                    </span>


                    <span v-else-if="props.column.field === 'orderNo'">
                            <b-link v-b-toggle.sidebar-right-orderDetail
                                @click="getOrderDetail(props.row.id)">
                                {{ '#'+props.row.orderNo }}
                            </b-link>
                        </span>

                    <!-- Column: Status -->
                    <span v-else-if="props.column.field === 'status'">
                        <b-badge :variant="statusVariant(props.row.status)">
                            {{ props.row.status }}
                        </b-badge>
                    </span>

                    <!-- Column: Action -->
                    <span v-else-if="props.column.field === 'action'">
                        <span>

                            <!-- <b-button class="mr-2" v-b-toggle.sidebar-right-orderDetail variant="primary"
                                @click="getOrderDetail(props.row.id)">Order Detail</b-button> -->

                            <b-button v-b-toggle.sidebar-right-paymentHistory variant="success"
                                @click="getOrderPaymentHistory(props.row.id)">Payment History</b-button>

                        </span>
                    </span>

                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>

            <b-sidebar id="sidebar-right-paymentHistory" width="90%" right shadow bg-variant="white" backdrop>
                <order-payment-history ref="orderPaymentHistory"></order-payment-history>
            </b-sidebar>

            <b-sidebar id="sidebar-right-orderDetail" width="95%" right shadow bg-variant="white" backdrop>
                <OrderDetail ref="orderDetail" />
            </b-sidebar>
            <!-- <b-sidebar id="sidebar-createTransaction" right shadow bg-variant="white" backdrop> -->
            <createTransaction :addWithdrawRefund="addWithdrawRefund"
                :getCustomerDetailsWalletOrder="getCustomerDetailsWalletOrder"
                :createTransactionTitle="createTransactionTitle" :walletId="walletId" :customerId="customerId" />
            <!-- </b-sidebar> -->
        </div>
        <b-row>
            <!-- <b-button disabled variant="secondary" class="mt-1 mr-1">Export To Excel</b-button> -->
            <b-button disabled variant="secondary" class="mt-1 mr-1">SMS Pending Balance</b-button>
            <b-button disabled variant="secondary" class="mt-1 mr-1">Send Email</b-button>
            <b-button disabled variant="secondary" class="mt-1 mr-1">Whatsapp Pending Balance</b-button>
            <b-button variant="primary" class="mt-1 mr-1" @click="createNewOrder">Create Order</b-button>
            <!-- <b-button disabled variant="primary" class="mt-1 mr-1">Create Order Per Weight</b-button> -->
            <!-- <b-button disabled variant="dark" class="mt-1 mr-1">Deliver</b-button> -->
        </b-row>
    </b-card>
</template>
  
<script>
import {
    VBToggle, BCol, BRow, BFormTextarea, BButton, BSidebar,BLink, BForm, BAvatar, BBadge, BCard, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import OrderDetail from '../order/OrderDetail.vue'
import api from '@/store/api'
import createTransaction from '../wallet/CreateTransaction.vue'
import util from '@/store/utils'
import store from '@/store'
import moment from 'moment'
import OrderPaymentHistory from '../order/OrderPaymentHistory.vue'

export default {
    components: {
        VueGoodTable, VBToggle,
        BCol, BRow, BForm, BAvatar,
        BCard, BButton, BFormTextarea,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BSidebar, vSelect,BLink, OrderDetail, createTransaction, util, OrderPaymentHistory
    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            roles: JSON.parse(localStorage.getItem('roles')),
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Order No',
                    field: 'orderNo',
                },
                {
                    label: 'Order Date',
                    field: 'orderCreateDate',
                },
                {
                    label: 'Due Date',
                    field: 'dueDate',
                },
                {
                    label: 'Total Items',
                    field: 'totalItems',
                },
                {
                    label: 'Total Pcs',
                    field: 'totalPices',
                },
                {
                    label: 'Under Process',
                    field: 'itemUnderProcess',
                },
                {
                    label: 'Ready',
                    field: 'itemReady',
                },
                {
                    label: 'Delivered',
                    field: 'itemDelevered',
                },
                // {
                //     label: 'Assigned / Out For Delivery',
                //     field: 'itemAssignedForDelivery',
                // },
                {
                    label: 'Total Amount',
                    field: 'totalNetAmount',
                },
                {
                    label: 'Balance Amount',
                    field: 'totalDueAmount',
                },
                {
                    label: 'Order Status',
                    field: 'orderStatus',
                },
                {
                    label: 'Store Name',
                    field: 'storeName',
                    width: '230px'
                },
                {
                    label: 'Action',
                    field: 'action',
                    width: '200px'
                },
            ],
            customerId: 0,
            rows: [],
            searchTerm: '',
            orderStatusOptions: [],
            orderStatus: null,
            storeNameOptions: [],
            storeName: { 'id': this.$store.state.storeId, 'storeName': this.$store.state.storeName },

            fullName: '',
            address: '',
            mobileNo: '',
            emailId: '',
            remark: '',
            customerPreference: '',
            discount: '',
            communicationPreference: '',
            barcode: '',
            homeDelivery: '',
            gstin: '',
            membershipId: '',
            facebookId: '',
            registrationSource: '',
            messageOnSearchCustomer: '',
            messageOnGarmentDelivery: '',

            addWithdrawRefund: 'Add',
            createTransactionTitle: '',

            walletId: 0,
            walletBalance: 0,
            lastVisit: '',
            pendingOrder: 0,
            amountDue: 0,
            pcsUnderProcess: 0,
            pcsReady: 0,
            orderDetailsItemList: {},
            customerLastTransactionOptions: []


        }
    },
    methods: {
        createNewOrder() {
            this.$router.push({ name: 'newOrder', params: { mobileNo: this.mobileNo } })
        },

        convertTimeStampToDateWithoutTime(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY');
            } else {
                return '';
            }
        },

        convertTimeStampToDate(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },
        getCustomerDetails(id) {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/customers/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));

                    self.fullName = response.data.namePrefix + " " + response.data.name;
                    self.address = response.data.address;
                    self.mobileNo = response.data.mobileNo;
                    self.emailId = response.data.emailId;
                    self.remark = response.data.remarks;
                    if (response.data.customerPreference == null) {
                        self.customerPreference = '';
                    } else {
                        self.customerPreference = response.data.customerPreference.name;
                    }
                    self.discount = response.data.defaultDiscountPercentage + ' %';
                    if (response.data.communicationPrefrence == null) {
                        self.communicationPreference = '';
                    } else {
                        self.communicationPreference = response.data.communicationPrefrence.name;
                    }
                    if (response.data.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    self.barcode = response.data.barcode;
                    self.gstin = response.data.gstin;
                    self.membershipId = response.data.membershipId;
                    self.marketingSource = response.data.marketingSource;
                    self.alternatePhone = response.data.alternetPhone;
                    self.facebookId = response.data.facebookId;
                    self.registrationSource = response.data.registrationSource;
                    self.messageOnSearchCustomer = response.data.msgOnSearchCustomer;
                    self.messageOnGarmentDelivery = response.data.msgOnGarmentDelevery;

                    self.getCustomerDetailsWalletOrder(id);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCustomerDetailsWalletOrder(id) {
            let self = this;

            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/customersDetails/' + id, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.walletId = response.data.walletId;
                    self.walletBalance = response.data.walletBalance;
                    self.lastVisit = self.convertTimeStampToDateLocal(response.data.lastVisitDate);
                    self.pendingOrder = response.data.pendingOrders;
                    self.amountDue = response.data.amountDue;
                    self.pcsUnderProcess = response.data.picUnderProcess;
                    self.pcsReady = response.data.picReadyToDispatch;

                    self.getOrderList(id);
                })
                .catch(function (error) {
                    console.log(error);
                });

        },
        getOrderList(customerId) {
            let self = this;
            var axios = require('axios');
            var data = JSON.stringify(
                {
                    "customerId": customerId,
                    "orderStatus": self.orderStatus,
                    "storeId": self.storeName.id,
                }
            );
            axios(api.getApi('put', '/orders/getOrdersFilteredList', data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });


        },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions.push({ 'id': 0, 'storeName': 'All' })
                    for (let index = 0; index < response.data.length; index++) {
                        self.storeNameOptions.push(response.data[index]);

                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        topUpAddMoney() {
            this.customerId = this.$route.params.id;
            this.addWithdrawRefund = 'Add';
            this.createTransactionTitle = 'Add Money'
        },
        getOrderDetail(orderId) {
            let self = this;
            self.$refs.orderDetail.getOrderDetail(orderId);
        },
        getLastpaymentList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/getCustomersLastTransactions/' + self.customerId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerLastTransactionOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderStatusList() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getOrdersStatus', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.orderStatusOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getOrderPaymentHistory(id) {
            this.$refs.orderPaymentHistory.getOrderDetail(id);
        }
    },
    computed: {
    },
    created() {
        this.customerId = this.$route.params.id;
        this.getCustomerDetails(this.$route.params.id);
        this.getStoreName();
        this.getOrderStatusList();
        this.getLastpaymentList();
    },
}
</script>  
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';

.dropdown-toggle::after {
    display: none;
}

.addTopUp {
    padding: 0.3rem;
}

.amountCredit {
    color: green;
}

.amountDebit {
    color: red;
}

.lastPaymentFontSize {
    font-size: 11px;
}

.lastPaymentHeight {
    height: 300px;
    overflow-y: auto;
}
</style>